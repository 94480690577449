/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import { Link } from "react-router-dom";

import bg1 from '../assets/images/bg/teamhospital.jpeg'
//import bg1 from '../assets/images/bg/03.jpg'
import about from '../assets/images/about/DoctorAubouUs.png'
import Navbar from "../components/navbar";
import { contact } from "../data/data";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";


import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';


import {
    RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
    RiMapPin2Line,
    FiFacebook,
    FiLinkedin,
    FiGithub,
    MdOutlineEventNote,
    FiCalendar,
    FiClock,
    FiHeart, FiEye, RiUser2Line, FiTwitter
  } from "./../assets/icons/vander";
  
  
  const icons = [
    { nome: 'olhos', icon: RiEyeFill },
    { nome: 'cabeça', icon: RiPsychotherapyFill },
    { nome: 'ostetoscopio', icon: RiStethoscopeFill },
    { nome: 'capsula', icon: RiCapsuleFill },
    { nome: 'microscopio', icon: RiMicroscopeFill },
    { nome: 'cardiologia', icon: RiPulseFill },
    { nome: 'coracao', icon: RiEmpathizeFill },
    { nome: 'celulas', icon: RiMindMap },
    { nome: 'cerebro', icon: RiMentalHealthLine },
    { nome: 'apple', icon: RiAppleLine },
    { nome: 'fratura', icon: RiInfraredThermometerLine },
    { nome: 'calendar', icon: FiCalendar },
    { nome: 'doctor', icon: GrUserPolice },
    { nome: 'telefone', icon: FiPhone },
    { nome: 'email', icon: FiMail },
    { nome: 'mapa', icon: FiMapPin },
  ];

const Contact = () => {

    // State to track whether the "checkgeral" checkbox is checked
    const [loading, setLoading] = useState(false);

    const getIconByName = (name) => {
        const iconObject = icons.find(icon => icon.nome == name);
        return iconObject ? iconObject.icon : null;
      };
    
      let IconeCalendar = getIconByName("calendar");

    const [email, setEmail] = useState('');
    //const [telefone, setTelefone] = useState('');
    const [nome, setNome] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');


    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);

    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, []);


    useEffect(() => {
        console.log('Unidades:', unidades);
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina/8');

            console.log("info :");
            console.log(res.data);

            setInfo(res.data);

            console.log('Doctores');
            console.log(info[0]?.doctores);
            console.log('Consultas');
            console.log(info[0]?.consultas);

            setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };



    const handleContactar = async () => {
        try {
            // Verificar se todos os campos estão preenchidos com mais de um caractere
            if (
                email.length > 1 &&
                nome.length > 1 &&
                assunto.length > 1 &&
                mensagem.length > 1
            ) {
                // Verificar se o e-mail é válido
                if (!isEmailValid(email)) {
                    enqueueSnackbar('Por favor, forneça um e-mail válido.', { variant: 'error' });
                    return;
                }

                setLoading(true);
                const formData = new FormData();
                formData.append('titulo', assunto);
                formData.append('descricao', mensagem);
                formData.append('email', email);
                //formData.append('telefone', telefone);
                formData.append('nome', nome);

            
                const response = await http.post('/contactar-equalizador', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
 
                console.log(response);

                setLoading(false);
                enqueueSnackbar('Recebemos o seu e-mail, muito obrigado.', { variant: 'success' });

                setNome('');
                //setTelefone(''); 
                setEmail('');
                setAssunto('');
                setMensagem('');

            } else {
                enqueueSnackbar('Por favor, preencha todos os campos.', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro. Por favor, tente novamente mais tarde.', { variant: 'error' });
            setLoading(false);
        }
    };

    const isEmailValid = (email) => {
        // Expressão regular para validar e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <>
            <Navbar manuClass="navigation-menu nav-left nav-light" containerClass="container" />

            {info[0]?.banner != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.banner?.designacao}{" "}</h3>
                                    <p className="para-desc mx-auto text-white-50">{info[0]?.banner?.descricao}{" "}</p>

                                    <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                            <li className="breadcrumb-item"><Link to="/">HRL</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Contacte-nos</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}

            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="mt-100 mt-60">
                <div className="container">
                    <div className="row">
                        { info[0]?.paragrafos?.map((item, index) => {
                            const Icon = getIconByName(item?.icone);
                            if (!Icon) return null; // Ensure Icon is not null
                            return (
                                <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0" key={index}>
                                    <div className="card features feature-primary text-center border-0">
                                        <div className="icon text-center rounded-md mx-auto">
                                            <Icon className="h3 mb-0" />
                                        </div>
                                        <div className="card-body p-0 mt-3">
                                            <h5>{index == 0?'Telefone':index==1?'E-mail':'Local'}</h5>
                                            <p className="text-muted mt-3">{item.descricao}</p>
                                            <Link to={item.link} className="link">{item.titulo}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="me-lg-5">
                                <img src={url_root+"/storage/"+info[0]?.paragrafos[3].imagem} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="custom-form rounded shadow p-4">
                                <h5 className="mb-4">Envie uma mensagem !</h5>
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Seu Nome <span className="text-danger">*</span></label>
                                                <input name="name" id="name" type="text" className="form-control border rounded" placeholder="Primeiro Nome :" value={nome}
                                                    onChange={(e) => setNome(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Seu E-mail <span className="text-danger">*</span></label>
                                                <input name="email" id="email" type="email" className="form-control border rounded" placeholder="seu e-mail :" value={email}
                                                    onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Assunto</label>
                                                <input name="subject" id="subject" className="form-control border rounded" placeholder="Assunto :" value={assunto}
                                                    onChange={(e) => setAssunto(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label">Mensagem <span className="text-danger">*</span></label>
                                                <textarea name="comments" id="comments" rows="4" className="form-control border rounded" placeholder="Sua Mensagem :" value={mensagem}
                                                    onChange={(e) => setMensagem(e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <span id="submit" name="send" className="btn btn-primary" onClick={() => handleContactar()}>Enviar mensagem</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-100 mt-60">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="card map border-0">
                                <div className="card-body p-0">
                                    <iframe src="https://maps.google.com/maps?q=Benguela&t=&z=13&ie=UTF8&iwloc=&output=embed" style={{ border: '0' }} title="myfram" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}



export default function WrappedFooter() {
    return (
        <SnackbarProvider>
            <Contact />
        </SnackbarProvider>
    );
}
