import React from 'react';
import { Link } from "react-router-dom";

//import logoLight from "../assets/images/logo-light.png"

import { FiFacebook, FiLinkedin, FiInstagram, FiTwitter, FiMail, FiPhone, FiMapPin, FiYoutube } from '../assets/icons/vander'

import logoHRL from '../assets/images/logo-hrl.png'

export default function Footer({ footer, consultas }) {
    return (

        <>
            <footer className="">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4 mb-0 mb-md-4 pb-0 pb-md-2">
                            <Link to="#" className="logo-footer">
                                <img src={logoHRL} height="22" alt="" />
                            </Link>
                            {footer ? <><p className="mt-4 me-xl-5">{footer[3]?.descricao}</p></> : <></>}

                        </div>

                        <div className="col-xl-7 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Institucional</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="/aboutus" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Sobre Nós</Link></li>
                                        <li><Link to="/pharmacy-shop" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Unidades</Link></li>
                                        <li><Link to="/pharmacy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Serviços</Link></li>
                                        <li><Link to="/doctor-team-two" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Doctores</Link></li>

                                        <li><Link to="/blogs" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Artigos</Link></li>
                                        {/* <li><Link to="/login" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Entrar</Link></li> */}
                                    </ul>
                                </div>

                                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Serviços</h5>
                                    <ul className="list-unstyled footer-list mt-4">


                                        {consultas?.slice(0, 5).map((item, index) => {
                                            return (
                                                <li><Link to={"/servico-detail/"} className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> {item?.designacao}</Link></li>)
                                        })}

                                        <li><Link to="/pharmacy" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Ver Todas</Link></li>

                                    </ul>
                                </div>

                                <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Contacte-nos</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li className="d-flex align-items-center">
                                            <FiMail className="fea icon-sm text-foot align-middle" />

                                            {footer ? <><Link to="mailto:contact@example.com" className="text-foot ms-2">{footer[0]?.titulo}</Link></> : <></>}
                                        </li>

                                        <li className="d-flex align-items-center">
                                            <FiPhone className="fea icon-sm text-foot align-middle" />

                                            {footer ? <><Link to="tel:+244-468-854" className="text-foot ms-2">{footer[1]?.titulo}</Link></> : <></>}
                                        </li>

                                        <li className="d-flex align-items-center">
                                            <FiMapPin className="fea icon-sm text-foot align-middle" />

                                            {footer ? <><Link to="#" className="video-play-icon text-foot ms-2">{footer[2]?.descricao}</Link></> : <></>}
                                        </li>
                                    </ul>

                                    <ul className="list-unstyled social-icon footer-social mb-0 mt-4">

                                    {footer ? <><li className="list-inline-item"><Link to={footer[4]?.descricao} className="rounded-pill"><FiLinkedin className="fea icon-sm fea-social" /></Link></li></>:<></>}
                                        

                                        {footer ? <> <li className="list-inline-item"><Link to={footer[5]?.descricao} className="rounded-pill"><FiFacebook className="fea icon-sm fea-social" /></Link></li></>:<></>}
                                       

                                        {footer ? <><li className="list-inline-item"><Link to={footer[6]?.descricao} className="rounded-pill"><FiInstagram className="fea icon-sm fea-social" /></Link></li></>:<></>}
                                        

                                        {footer ? <><li className="list-inline-item"><Link to={footer[7]?.descricao} className="rounded-pill"><FiYoutube className="fea icon-sm fea-social" /></Link></li></>:<></>}
                                        



                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="pt-4 footer-bar">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="text-sm-start text-center">
                                    <p className="mb-0">{new Date().getFullYear()}© HRL. Desenhado e Desenvolvido <i className="mdi mdi-heart text-danger"></i> por <Link to="https://" target="_blank" className="text-reset">DJL</Link>.</p>
                                </div>
                            </div>

                            <div className="col-sm-6 mt-4 mt-sm-0">
                                <ul className="list-unstyled footer-list text-sm-end text-center mb-0">
                                    <li className="list-inline-item"><Link to="/#" className="text-foot me-2">Termos</Link></li>
                                    <li className="list-inline-item"><Link to="#" className="text-foot me-2">Privacidade</Link></li>
                                    <li className="list-inline-item"><Link to="/aboutus" className="text-foot me-2">Sobre</Link></li>
                                    <li className="list-inline-item"><Link to="/contact" className="text-foot me-2">Contacto</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}