/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/hero_bg_1.jpg'
import bg2 from '../../assets/images/bg/pharm02.jpg'
import bg3 from '../../assets/images/bg/pharm03.jpg'
import cta from '../../assets/images/pharmacy/cta.jpg'

import Counter from "../../components/counter";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import Navbar from "../../components/navbar";

import Carousel from 'react-bootstrap/Carousel';
import { unidadeData, pharmaCategories } from "../../data/data";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import MarcarConsulta from "../../components/MarcarConsulta";

import {
    RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
    RiMapPin2Line,
    FiFacebook,
    FiLinkedin,
    FiGithub,
    MdOutlineEventNote,
    FiCalendar,
    FiClock,
    FiHeart, FiEye, RiUser2Line, FiTwitter
  } from '../../assets/icons/vander'

const icons = [
    { nome: 'olhos', icon: RiEyeFill },
    { nome: 'cabeça', icon: RiPsychotherapyFill },
    { nome: 'ostetoscopio', icon: RiStethoscopeFill },
    { nome: 'capsula', icon: RiCapsuleFill },
    { nome: 'microscopio', icon: RiMicroscopeFill },
    { nome: 'cardiologia', icon: RiPulseFill },
    { nome: 'coracao', icon: RiEmpathizeFill },
    { nome: 'celulas', icon: RiMindMap },
    { nome: 'cerebro', icon: RiMentalHealthLine },
    { nome: 'apple', icon: RiAppleLine },
    { nome: 'fratura', icon: RiInfraredThermometerLine },
    { nome: 'calendar', icon: FiCalendar },
    { nome: 'doctor', icon: GrUserPolice },
  ];

export default function PharmacyShop() {

    const { http, url_root } = AuthUser();

    const getIconByName = (name) => {
        const iconObject = icons.find(icon => icon.nome == name);
        return iconObject ? iconObject.icon : null;
      };
    
      let IconeCalendar = getIconByName("calendar");

    let settings = {
        container: '.slider-range-four',
        items: 4,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 2
            },


            320: {
                items: 1
            },
        },
    };

    let [index, setIndex] = useState(0);

    let handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [unidades, setUnidades] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [doctores, setDoctores] = useState([]);

    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, []);


    useEffect(() => {
        console.log('Unidades:', unidades);
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
        setConsultas(info[0]?.consultas?.slice(0, 100) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina/5');

            console.log("info :");
            console.log(res.data);

            setInfo(res.data);

            console.log('Doctores');
            console.log(info[0]?.doctores);
            console.log('Consultas');
            console.log(info[0]?.consultas);

            setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };


    return (
        <>
            <Navbar navDark={true} manuClass="navigation-menu nav-light nav-left" containerClass="container" />


            {info[0]?.banner != null ? <>
                <section className="home-slider position-relative">

                    <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} interval={2000} >

                        {/* Repeat it */}
                        <Carousel.Item>
                            <div className="carousel-item active">
                                <div className="bg-half-170 d-table align-items-center w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition: 'center' }}>
                                    <div className="bg-overlay bg-overlay-dark"></div>
                                    <div className="container">
                                        <div className="row mt-5">
                                            <div className="col-lg-12">
                                                <div className="heading-title">
                                                    <h1 className="sub-title mb-4 text-white title-dark fw-bold">{info[0]?.banner?.designacao}{" "}</h1>
                                                    <p className="para-desc text-white-50 mb-0">{info[0]?.banner?.descricao}{" "}</p>

                                                    <div className="mt-4 pt-2">
                                                        <Link to="/pharmacy-product-detail" className="btn btn-primary">Mais info.</Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>



                    </Carousel>
                </section>
            </> : <></>}


            <section className="section">

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mb-0">{info[0]?.paragrafos[0]?.titulo}</h5>
                        </div>
                    </div>

                    <div className="row">
                        {unidades.slice(0, 100).map((item, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2" key={index}>
                                    <div className="card shop-list border-0">
                                        <ul className="label list-unstyled mb-0">
                                            {/* <li><Link to="" className="badge rounded-pill badge-success">Featured</Link></li> */}
                                        </ul>
                                        <div className="shop-image position-relative overflow-hidden rounded shadow">
                                            <Link to={"/pharmacy-product-detail/"+item.id}><img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" /></Link>
                                            <ul className="list-unstyled shop-icons">
                                                <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
                                                <li className="mt-2"><Link to="/pharmacy-product-detail" className="btn btn-icon btn-pills btn-soft-primary"><FiEye className="icons" /></Link></li>
                                                {/* <li className="mt-2"><Link to="/pharmacy-shop-cart" className="btn btn-icon btn-pills btn-soft-warning"><FiShoppingCart className="icons" /></Link></li> */}
                                            </ul>

                                            {/* <Counter /> */}
                                        </div>
                                        <div className="card-body content pt-4 p-2">
                                            <Link to={"/pharmacy-product-detail/"+item.id} className="text-dark product-name h6">{item?.designacao}</Link>
                                            <div className="d-flex justify-content-between mt-1">
                                                <h6 className="text-muted small font-italic mb-0 mt-1">{item?.price}</h6>
                                                <ul className="list-unstyled text-warning mb-0">
                                                    <li className="list-inline-item">
                                                        <div className="icon text-center rounded-md">
                                                            <i className="fa fa-user" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mb-0">{info[0]?.paragrafos[1]?.titulo}</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mt-4 pt-2">
                            <div className="slider-range-four">
                                <TinySlider settings={settings}>
                                    {consultas?.map((item, index) => {
                                        return (
                                            <div className="tiny-slide" key={index}>
                                                <Link to={"/servico-detail/"+item?.id} className="card pharpachy-categories border-0 rounded overflow-hidden">
                                                    <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                                                    <div className="category-title">
                                                        <span className="text-dark title-white"><span className="h5">{item.designacao}</span><br />{item.categoria}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </TinySlider>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="container mt-100 mt-60">
                    <div className="row align-items-lg-end">
                        <div className="col-md-6">
                            <div className="me-xl-3">
                                <div className="section-title mb-4 pb-2">

                                    <IconeCalendar className="text-primary h2" />
                                    <h4 className="title mb-4"> {info[0]?.marcar_consulta[0]?.titulo} </h4>{" "}
                                    <p className="text-muted para-desc mb-0">
                                        {" "}
                                        {info[0]?.marcar_consulta[0]?.descricao}{" "}
                                    </p>{" "}
                                </div>{" "}
                                {info[0]?.marcar_consulta?.slice(1, 4).map((item, index) => {
                                    let Icon = getIconByName(item?.icone);
                                    return (
                                        <div
                                            className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                                            key={index}
                                        >
                                            <Icon className="icons display-3 mb-0 text-primary" />
                                            <div className="ms-3">
                                                <h5 className="titles"> {item.titulo} </h5>{" "}
                                                <p className="text-muted para mb-0"> {item.descricao} </p>{" "}
                                            </div>{" "}
                                            <div className="big-icon">
                                                <Icon />
                                            </div>{" "}
                                        </div>
                                    );
                                })}{" "}
                            </div>{" "}
                        </div>

                        <MarcarConsulta doctores={info[0]?.doctores} consultas={info[0]?.consultas} especialidades={info[0]?.especialistas} />


                    </div>{" "}
                </div>{" "}



            </section>
            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }
        </>
    )
}