/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";

import shop1 from '../../assets/images/pharmacy/shop/01.jpg'
import shop2 from '../../assets/images/pharmacy/shop/02.jpg'
import shop3 from '../../assets/images/pharmacy/shop/03.jpg'
import shop4 from '../../assets/images/pharmacy/shop/04.jpg'

import Navbar from "../../components/navbar";

import { productData, pharmaCategories, contactData, unidadeHospitalar } from "../../data/data";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import { FiHeart, FiEye, MdOutlineEventNote } from '../../assets/icons/vander'
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import bg1 from '../../assets/images/bg/servico-banner-3.jpeg';
import MarcarConsulta from "../../components/MarcarConsulta";

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';


const PharmacyProductDetail = () => {

    const { id } = useParams();

    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [paragrafos, setParagrafos] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [unidade, setUnidade] = useState([]);

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, [id]);


    useEffect(() => {
        setConsultas(info[0]?.consultas || []);
        setUnidades(info[0]?.unidades || []);
        setUnidade(info[0]?.unidade || {});
        setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina-single/5/' + id);

            console.log("info :");
            console.log(res.data);


            setInfo(res.data);

            setConsultas(info[0]?.consultas || []);
            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);
            setUnidade(info[0]?.unidade || {});
            setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
            setUnidades(info[0]?.unidades || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    let settings = {
        container: '.slider-range-four',
        items: 4,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 2
            },


            320: {
                items: 1
            },
        },
    };
    let settings2 = {
        container: '.client-review-slider',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
    };

    let data = productData.find((item) => item.id === parseInt(id))
    let productImage = [shop1, shop2, shop3, shop4];

    console.log(bg1);


    return (
        <>


            <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container" />

            {info != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.unidade?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.unidade?.designacao}</h3>
                                    <p className="para-desc mx-auto text-white-50">{info[0]?.unidade?.descricao}</p>

                                    <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                            <li className="breadcrumb-item"><Link to="/">HRL</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Informações Da Unidade</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}





            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        {info != null ?
                            <div className="col-md-5">
                                <div className="client-review-slider">
                                    <TinySlider settings={settings2}>
                                        {paragrafos?.slice(1, 6).map((item, index) => {
                                            return (
                                                <div className="tiny-slide" key={index}>
                                                    <img src={url_root + "/storage/" + item?.imagem} className="img-fluid rounded" alt="" />
                                                </div>
                                            )
                                        })}
                                    </TinySlider>
                                </div>
                            </div> : <div className="col-md-5">
                                <img src={info[0]?.unidade?.imagem} className="img-fluid rounded" alt="" />
                            </div>

                        }

                        <div className="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-md-4">
                                <h4 className="title">{paragrafos[0]?.titulo}</h4>
                                {/* <h5 className="text-muted">{data?.price ? data.price : '$21.00 '}</h5> */}
                                <ul className="list-unstyled text-warning h5 mb-0">

                                    <li className="list-inline-item me-2 h6 text-muted">{paragrafos[0]?.descricao}</li>

                                </ul>

                                <h5 className="mt-4 py-2">Informação :</h5>
                                <p className="text-muted">{paragrafos[1]?.descricao}</p>

                                <p className="text-muted">{paragrafos[2]?.descricao}</p>

                                <p className="text-muted">{paragrafos[3]?.descricao}</p>

                                {/* <div className="d-flex shop-list align-items-center">
                                <h6 className="mb-0">Quantity:</h6>
                                <CounterTwo/>
                            </div> */}

                                {/* <div className="mt-4 pt-2">
                                <Link to="#" className="btn btn-primary">Shop Now</Link>
                                <Link to="/pharmacy-shop-cart" className="btn btn-soft-primary ms-2">Add to Cart</Link>
                            </div> */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4">Serviços</h4>
                                <p className="text-muted para-desc mb-0">Explore nossa ampla gama de serviços médicos para encontrar o cuidado especializado que você precisa para uma saúde ótima.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mt-4 pt-2">
                            <div className="slider-range-four">
                                <TinySlider settings={settings}>
                                    {consultas?.map((item, index) => {
                                        return (
                                            <div className="tiny-slide" key={index}>
                                                <Link to={"/servico-detail/"+item?.id} className="card pharpachy-categories border-0 rounded overflow-hidden">
                                                    <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                                                    <div className="category-title">
                                                        <span className="text-dark title-white"><span className="h5">{item.designacao}</span><br />{item.categoria}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </TinySlider>
                            </div>
                        </div>
                    </div>


                </div>




                <div className="container mt-100 mt-60">
                    <div className="row align-items-lg-end">
                        <div className="col-md-6">
                            <div className="me-xl-3">
                                <div className="section-title mb-4 pb-2">
                                    <MdOutlineEventNote className="text-primary h2" />
                                    <h4 className="title mb-4"> Agende Sua Consulta </h4>{" "}
                                    <p className="text-muted para-desc mb-0">
                                        {" "}
                                        Excelente médico para fornecer assistência imediata, tratamento de emergência ou uma simples consulta para você e sua família.{" "}
                                    </p>{" "}
                                </div>{" "}
                                {contactData.map((item, index) => {
                                    let Icon = item.icon;
                                    return (
                                        <div
                                            className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                                            key={index}
                                        >
                                            <Icon className="icons display-3 mb-0 text-primary" />
                                            <div className="ms-3">
                                                <h5 className="titles"> {item.title} </h5>{" "}
                                                <p className="text-muted para mb-0"> {item.desc} </p>{" "}
                                            </div>{" "}
                                            <div className="big-icon">
                                                <Icon />
                                            </div>{" "}
                                        </div>
                                    );
                                })}{" "}
                            </div>{" "}
                        </div>

                        <MarcarConsulta  doctores={info[0]?.doctores} consultas={info[0]?.consultas} especialidades={info[0]?.especialistas} />


                    </div>{" "}
                </div>{" "}




              {/* UNIDADES SANITARIAS INICIO */}

      {unidades ? <>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-2 pb-2 text-center">
                <span className="badge rounded-pill bg-soft-primary mb-3">
                  {" "}
                  Unidades{" "}
                </span>{" "}
                <h4 className="title mb-2"> Confira Outras Unidades </h4>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="row">
            <div className="col-lg-12 mt-2 pt-2">

              <div className="slider-range-three">

                <TinySlider settings={settings}>

                  {" "}

                  {unidades?.map((item, index) => {
                    return (
                      <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2" key={index}>
                        <div className="card shop-list border-0">

                          <div className="shop-image position-relative overflow-hidden rounded shadow">
                            <Link to={"/pharmacy-product-detail/"+item?.id}><img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" /></Link>
                            <ul className="list-unstyled shop-icons">
                              <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
                              <li className="mt-2"><Link to="/pharmacy-product-detail" className="btn btn-icon btn-pills btn-soft-primary"><FiEye className="icons" /></Link></li>
                            </ul>
                          </div>

                          <div className="card-body content text-center">
                            <Link
                              to={"/pharmacy-product-detail/"+item?.id}
                              className="title text-dark h5 d-block mb-0"
                            >
                              {" "}
                              {item.designacao}{" "}
                            </Link>{" "}
                            <small className="text-muted speciality">
                              {" "}
                              {/* {item.price} */}
                              {" "}
                            </small>{" "}
                          </div>{" "}

                        </div>
                      </div>
                    );
                  })}{" "}

                </TinySlider>{" "}

              </div>{" "}


            </div>{" "}
          </div>{" "}
        </div>
      </> : <></>}

      {/* UNIDADES SANITARIAS FIM */}



            </section>
            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}



export default function WrappedFooterPharmacyProductDetail() {
    return (
        <SnackbarProvider>
            <PharmacyProductDetail />
        </SnackbarProvider>
    );
}

