import {RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill,RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine,TbAmbulance,RiShieldCrossLine, RiBriefcase4Line,FiAirplay,MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine,RiHourglassLine,RiHeartLine,RiHeartPulseLine,RiStethoscopeLine,RiVirusLine,RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward,LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, } from '../assets/icons/vander'

import unidade1 from '../assets/images/unidades/unidade (2).jpg'
import unidade2 from '../assets/images/unidades/unidade (5).jpg'
import unidade3 from '../assets/images/unidades/unidade (7).jpeg'
import unidade4 from '../assets/images/unidades/unidade (8).jpg'
import unidade5 from '../assets/images/unidades/unidade (9).jpg'
import unidade6 from '../assets/images/unidades/unidade (3).jpg'

import dr1 from '../assets/images/doctors/doctor (1).jpg'
import dr2 from '../assets/images/doctors/doctor (5).jpg'
import dr3 from '../assets/images/doctors/doctor (2).jpg'
import dr4 from '../assets/images/doctors/doctor (7).jpg'
import dr5 from '../assets/images/doctors/doctor (3).jpg'
import dr6 from '../assets/images/doctors/doctor (4).jpg'
import dr7 from '../assets/images/doctors/doctor (6).jpg'
import dr8 from '../assets/images/doctors/doctor (8).jpg'
import dr9 from '../assets/images/doctors/doctor (1).jpg'
import dr10 from '../assets/images/doctors/doctor (2).jpg'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'
import client9 from '../assets/images/client/09.jpg'
import client10 from '../assets/images/client/10.jpg'

import blog1 from '../assets/images/blog/artigos (1).jpeg'
import blog2 from '../assets/images/blog/artigos (1).jpg'
import blog3 from '../assets/images/blog/artigos (4).jpg'
import blog4 from '../assets/images/blog/artigos (3).jpg'
import blog5 from '../assets/images/blog/artigos (5).jpg'
import blog6 from '../assets/images/blog/artigos (7).jpg'
import blog7 from '../assets/images/blog/artigos (6).jpg'
import blog8 from '../assets/images/blog/artigos (8).jpg'
import blog9 from '../assets/images/blog/artigos (9).jpg'

import partners1 from '../assets/images/client/amazon.png'
import partners2 from '../assets/images/client/google.png'
import partners3 from '../assets/images/client/lenovo.png'
import partners4 from '../assets/images/client/paypal.png'
import partners5 from '../assets/images/client/shopify.png'
import partners6 from '../assets/images/client/spotify.png'

import payment1 from '../assets/images/payment/mastercard.png'
import payment2 from '../assets/images/payment/discover.png'
import payment3 from '../assets/images/payment/rupay.png'
import payment4 from '../assets/images/payment/american.png'

import category1 from '../assets/images/pharmacy/skin.jpg'
import category2 from '../assets/images/pharmacy/sexual.jpg'
import category3 from '../assets/images/pharmacy/weight.jpg'
import category4 from '../assets/images/pharmacy/pain.jpg'
import category5 from '../assets/images/pharmacy/heart.jpg'
import category6 from '../assets/images/pharmacy/cough.jpg'
import category7 from '../assets/images/pharmacy/diabetes.jpg'
import category8 from '../assets/images/pharmacy/cancer.jpg'

import product1 from '../assets/images/pharmacy/shop/thermometer.jpg'
import product2 from '../assets/images/pharmacy/shop/stethoscope.jpg'
import product3 from '../assets/images/pharmacy/shop/pulse-oximeter.jpg'
import product4 from '../assets/images/pharmacy/shop/medicine.jpg'
import product5 from '../assets/images/pharmacy/shop/smoking-habit.jpg'
import product6 from '../assets/images/pharmacy/shop/sanitizer.jpg'
import product7 from '../assets/images/pharmacy/shop/nicotex.jpg'
import product8 from '../assets/images/pharmacy/shop/medigrip.jpg'
import product9 from '../assets/images/pharmacy/shop/masks.jpg'
import product10 from '../assets/images/pharmacy/shop/handwash.jpg'
import product11 from '../assets/images/pharmacy/shop/herbal-care.jpg'
import product12 from '../assets/images/pharmacy/shop/medical-equptment.jpg'
import product13 from '../assets/images/pharmacy/shop/praganews.jpg'
import product14 from '../assets/images/pharmacy/shop/ashwagandha.jpg'
import product15 from '../assets/images/pharmacy/shop/beby-products.jpg'
import product16 from '../assets/images/pharmacy/shop/kidney-tray.jpg'
import product17 from '../assets/images/pharmacy/shop/diabend.jpg'
import product18 from '../assets/images/pharmacy/shop/facewash.jpg'

export const medicalServices = [
    {
        icon:RiEyeFill,
        title:'Cuidados optico',
        desc:'Cuidados ópticos de alta qualidade, incluindo exames de vista completos, diagnósticos precisos e tratamentos personalizados para garantir a saúde dos seus olhos.'
    },
    {
        icon:RiPsychotherapyFill,
        title:'Fisioterapia',
        desc:'Serviços de fisioterapia especializados para reabilitação, alívio da dor e melhoria da mobilidade, promovendo uma recuperação rápida e eficaz.'
    },
    {
        icon:RiStethoscopeFill,
        title:'Primeiros socorros',
        desc:'Atendimento rápido e eficaz de primeiros socorros para emergências, garantindo cuidados imediatos e estabilização do paciente.'
    },
    {
        icon:RiCapsuleFill,
        title:'Cuidados dentarios',
        desc:'Cuidados dentários completos, incluindo limpeza, tratamentos, restaurações e prevenção para manter seu sorriso saudável e bonito.'
    },
    {
        icon:RiMicroscopeFill,
        title:'Ortopedia',
        desc:'Serviços ortopédicos abrangentes, incluindo diagnóstico preciso, tratamento especializado e reabilitação, visando restaurar a função e a mobilidade musculoesquelética.'
    },
    {
        icon:RiPulseFill,
        title:'Cardiologia',
        desc:'Tratamentos cardiológicos avançados, incluindo diagnóstico, monitoramento e intervenções especializadas para garantir a saúde do coração e prevenir doenças cardiovasculares.'
    },
    {
        icon:RiEmpathizeFill,
        title:'Genecologia',
        desc:'Serviços ginecológicos especializados, incluindo exames de rotina, diagnóstico precoce e tratamentos personalizados para cuidar da saúde reprodutiva e hormonal das mulheres.'
    },
    {
        icon:RiMindMap,
        title:'Neurologia',
        desc:'Serviços neurologicos especializados, oferecendo diagnóstico avançado, tratamento personalizado e cuidados compassivos para uma variedade de condições neurológicas.'
    },
    {
        icon:RiMentalHealthLine,
        title:'Dermatologia',
        desc:'Serviços dermatológicos completos, fornecendo diagnóstico preciso, tratamentos inovadores e cuidados preventivos para promover a saúde da pele e tratar uma variedade de condições dermatológicas, desde acne até doenças mais complexas.'
    },
    {
        icon:RiAppleLine,
        title:'Nutrição',
        desc:'Serviços de nutrição abrangentes, incluindo avaliação nutricional, orientação alimentar personalizada e educação sobre hábitos saudáveis.'
    },
    {
        icon:RiInfraredThermometerLine,
        title:'Terapia Fisica',
        desc:'Serviços de terapia física especializados, fornecendo avaliação individualizada, tratamentos personalizados e exercícios terapêuticos para ajudar na recuperação de lesões, alívio da dor e melhoria da mobilidade'
    },
]

export const doctorData = [
    {
        image: dr1,
        name: 'Carlos Lamartini',
        speciality: 'Cuidados Visão',
        location: '63, Angola malange',
        time: 'Dom: 2:00 - 6:00',
        charges: 'Kz 75 AOA/ Visitas' 
    },
    {
        image: dr2,
        name: 'Cristo Mauro',
        speciality: 'M.B.B.S, Genecologista',
        location: '63, rua Grande, Angola',
        time: 'Seg: 2:00P - 6:00P',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr3,
        name: 'Alda Mario',
        speciality: 'M.B.B.S, Fisioterapeuta',
        location: '63, prenda Lote 19, AOA',
        time: 'Seg: 2:00P - 6:00',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr4,
        name: 'Toni Kivota',
        speciality: 'M.B.B.S, ortopedista',
        location: '63, Maianga, Angola',
        time: 'Ter: 2:00P - 6:00P',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr5,
        name: 'Jessica Kavita',
        speciality: 'M.B.B.S, Dentista',
        location: '63, prenda AOA',
        time: 'Ter: 2:00 - 6:00',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr6,
        name: 'Elsa Guimarães',
        speciality: 'Genecologista',
        location: '63, prenda, Angola',
        time: 'Qua: 2:00 - 6:00',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr7,
        name: 'Belita Magda',
        speciality: 'M.B.B.S, Urologista',
        location: '63, cazenga, Angola',
        time: 'Ter: 2:00 - 6:00',
        charges: 'Kz 75 AOA / Visitas' 
    },
    {
        image: dr8,
        name: 'Luis Dalas',
        speciality: 'M.B.B.S, Neurologista',
        location: '63, Cacuaco, Angola',
        time: 'Seg: 2:00 - 6:00',
        charges: 'Kz 75 AOA / Visitas' 
    },
]

export const counterData = [
    {
        target: '99',
        symbol: '%',
        titleOne: 'Positive feedback',
        titleTwo:'From Doctors'
    },
    {
        target: '25',
        symbol: '+',
        titleOne: 'Experienced Clinics',
        titleTwo:'High Qualified'
    },
    {
        target: '1251',
        symbol: '+',
        titleOne: 'Questions & Answers',
        titleTwo:'Your Questions'
    },
]

export const patientsData = [
    {
        image: client1,
        name: '- Dr. João Silva',
        title: 'C.E.O',
        desc:'" A saúde do paciente sempre vem em primeiro lugar, e minha missão é proporcionar o melhor cuidado possível. "',
        desc2:'" A saúde do paciente sempre vem em primeiro lugar, e minha missão é proporcionar o melhor cuidado possível. "'
    },
    {
        image: client2,
        name: '- Dra. Maria Fernandes',
        title: 'P.A',
        desc:'" A prevenção é a melhor forma de cuidar da saúde; estou aqui para guiar meus pacientes nesse caminho. "',
        desc2:'" A prevenção é a melhor forma de cuidar da saúde; estou aqui para guiar meus pacientes nesse caminho. "'
    },
    {
        image: client3,
        name: '- Dr. Carlos Oliveira',
        title: 'M.D',
        desc:'" Cada paciente é único e merece um tratamento personalizado e compassivo. "',
        desc2:'" Cada paciente é único e merece um tratamento personalizado e compassivo. "'
    },
    {
        image: client4,
        name: '- Dra. Ana Souza',
        title: 'Gestora',
        desc:'" Meu objetivo é ajudar meus pacientes a viverem vidas mais saudáveis e felizes através de cuidados médicos integrados. "',
        desc2:'" Meu objetivo é ajudar meus pacientes a viverem vidas mais saudáveis e felizes através de cuidados médicos integrados. "'
    },
    {
        image: client5,
        name: '- Dr. Pedro Lima',
        title: 'Secretario',
        desc:'" A confiança entre médico e paciente é fundamental para um tratamento eficaz e uma recuperação bem-sucedida. "',
        desc2:'" A confiança entre médico e paciente é fundamental para um tratamento eficaz e uma recuperação bem-sucedida. "'
    },
]

export const blogData = [
    {
        id:1,
        image: blog1,
        title: 'Novas Tecnologias de Diagnóstico',
        like: '10',
        comment:'00',
        date: '05 Abril de 2024',
        time:'5 min leitura'
    },
    {
        id:2,
        image: blog2,
        title: 'Campanha de Vacinação Infantil',
        like: '33',
        comment:'08',
        date: '12 Abril de 2024',
        time:'5 min de leitura'
    },
    {
        id:3,
        image: blog3,
        title: 'Semana da Saúde da Mulher',
        like: '14',
        comment:'02',
        date: '18 Abril de 2024',
        time:'5 min de leitura'
    },
    {
        id:4,
        image: blog4,
        title: 'Inauguração do Novo Centro de Maternidade',
        like: '09',
        comment:'02',
        date: '25 Abril de 2024',
        time:'5 min de leitura'
    },
    {
        id:5,
        image: blog5,
        title: 'Programa de Reabilitação Pós-COVID',
        like: '12',
        comment:'02',
        date: '02 Maio de 2024',
        time:'5 min de leitura'
    },
    {
        id:6,
        image: blog6,
        title: 'Treinamento Avançado para Nossa Equipe',
        like: '12',
        comment:'01',
        date: '10 Maio de 2024',
        time:'5 min de leitura'
    },
    {
        id:7,
        image: blog7,
        title: 'Nova Unidade de Raio-X em Operação',
        like: '14',
        comment:'03',
        date: '18 Maio de 2024',
        time:'5 min de leitura'
    },
    {
        id:8,
        image: blog8,
        title: 'Evento de Doação de Sangue',
        like: '33',
        comment:'08',
        date: '27 Maio de 2024',
        time:'5 min de leitura'
    },
    {
        id:9,
        image: blog9,
        title: 'Parceria com Universidades Locais',
        like: '01',
        comment:'04',
        date: '05 Junho de 2024',
        time:'5 min de leitura'
    },
]


export const unidadeHospitalar = [
    {
        id:1,
        image: unidade1,
        title: 'Hospital Geral',
        like: '15',
        comment:'08',
        date: 'Rua do só cabral',
        time:'5 min de leitura'
    },
    {
        id:2,
        image: unidade2,
        title: 'Hospital de Benguela',
        like: '15',
        comment:'08',
        date: 'Machado Saldanha',
        time:'5 min de leitura'
    },
    {
        id:3,
        image: unidade3,
        title: 'Hospital do Chongoroi',
        like: '33',
        comment:'08',
        date: 'Rua da Lama',
        time:'5 min de leitura'
    },
    {
        id:4,
        image: unidade4,
        title: 'Maternidade Geral',
        like: '10',
        comment:'08',
        date: 'Rua Augusto Ngangula',
        time:'5 min de leitura'
    },
    {
        id:5,
        image: unidade5,
        title: 'Hemoterapia',
        like: '16',
        comment:'08',
        date: 'Rua do Mauricio Rui',
        time:'5 min de leitura'
    },
    {
        id:6,
        image: unidade6,
        title: 'Hemodialise',
        like: '16',
        comment:'08',
        date: 'Rua do Mauricio Rui',
        time:'5 min de leitura'
    },
]

export const partners = [partners1,partners2,partners3,partners4, partners5,partners6]

export const category = [
    {
        icon: GrUserPolice ,
        title: 'Doctors',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiCapsuleLine,
        title: 'Clinics',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiMicroscopeLine,
        title: 'Labs',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: TbAmbulance,
        title: 'Emergency',
        desc: 'Due to its wide spread use as filler text'
    },
    {
        icon: RiShieldCrossLine,
        title: 'Insurance',
        desc: 'Due to its wide spread use as filler text'
    },
]
export const ctaData = [
    {
        icon:RiBriefcase4Line,
        title: 'Nossa Missão',
        desc:'Proporcionar cuidados médicos de excelência com compaixão e inovação, promovendo a saúde e o bem-estar de nossos pacientes e da comunidade.' 
    },
    {
        icon:FiAirplay,
        title: 'Nossa Visão',
        desc:'Ser referência em serviços de saúde, reconhecidos pela qualidade, inovação e atendimento humanizado, contribuindo para uma vida mais saudável e feliz.' 
    },
    {
        icon:MdOutlineFlip,
        title: 'Quem Somos ?',
        desc:'Somos uma equipe dedicada de profissionais de saúde comprometidos em oferecer cuidados personalizados e de alta qualidade, utilizando tecnologia avançada para garantir os melhores resultados para nossos pacientes.' 
    },
]

export const ctaDataMarcacaoConsulta = [
    {
        icon:RiBriefcase4Line,
        title: 'Escolha o Serviço',
        desc:'Selecione o tipo de consulta ou especialidade médica desejada. Navegue pela nossa lista de serviços para encontrar o atendimento que precisa.' 
    },
    {
        icon:FiAirplay,
        title: 'Selecione a Data e Hora',
        desc:'Escolha uma data e horário conveniente para sua consulta. Verifique a disponibilidade do médico e marque sua consulta online.' 
    },
    {
        icon:MdOutlineFlip,
        title: 'Confirme seus Dados',
        desc:'Preencha suas informações pessoais e confirme a marcação. Você receberá uma confirmação por email com todos os detalhes da sua consulta.' 
    },
]

export const accordionData = [
    {
        id:1,
        title:'Como posso agendar uma consulta?',
        desc: 'Você pode agendar uma consulta ligando para nossa central de atendimento ou acessando nosso site e preenchendo o formulário de agendamento online.'
    },
    {
        id:2,
        title:'Quais serviços de emergência vocês oferecem?',
        desc: 'Nossa equipe está preparada para lidar com uma ampla gama de emergências médicas, oferecendo atendimento imediato para condições graves e estabilização até que o tratamento adicional possa ser realizado.'
    },
    {
        id:3,
        title:'Vocês aceitam planos de saúde?',
        desc: 'Sim, aceitamos diversos planos de saúde. Entre em contato com nossa equipe de atendimento para verificar se seu plano é aceito e obter informações sobre cobertura.'
    },
    {
        id:4,
        title:'Quais são os horários de funcionamento da clínica?',
        desc: 'Nossa clínica funciona de segunda a sexta, das 8h às 18h, e aos sábados, das 8h às 12h. Para emergências, estamos disponíveis 24 horas por dia, 7 dias por semana.'
    },
]
export const contactData = [
    {
        icon:RiStethoscopeFill,
        title:'Tratamento De Sucesso',
        desc:'Tratamentos eficazes e comprovados para garantir sua recuperação e bem-estar.'
    },
    {
        icon:RiMicroscopeLine,
        title:'Tecnologia Moderna',
        desc:'Equipamentos de última geração para diagnósticos precisos e tratamentos avançados.'
    },
    {
        icon:GrUserPolice,
        title:'Doctores Certificados',
        desc:'Médicos altamente qualificados e certificados para oferecer o melhor cuidado à sua saúde.'
    },
]
export const latestAppointment = [
    {
        image:client1,
        name:'Dr. João Silva',
        date: 'Rua Augusto Ngangula, 2023'
    },
    {
        image:client2,
        name:'Dra. Maria Fernandes',
        date: 'Rua Pedro de Castro, 2023'
    },
    {
        image:client3,
        name:'Dr. Carlos Oliveira',
        date: 'Rua 1 de Junho, 2023'
    },
    {
        image:client4,
        name:'Dra. Ana Souza',
        date: 'Rua Marechal Rondom, 2023'
    },
    {
        image:client5,
        name:'Dr. Pedro Lima',
        date: 'Rua Pedro vandunem, 2023'
    },
]

export const upcomingAppointment = [
    {
        image:client6,
        name:'Cristino Murphy',
        date: 'Booking on 19th June, 2023'
    },
    {
        image:client7,
        name:'Cristino Murphy',
        date: 'Booking on 20th June, 2023'
    },
    {
        image:client8,
        name:'Alex Dirio',
        date: 'Booking on 31st Aug, 2023'
    },
    {
        image:client9,
        name:'Vrunda Koli',
        date: 'Booking on 1st Sep, 2023'
    },
    {
        image:client10,
        name:'Aisha Low',
        date: 'Booking on 5th May, 2023'
    },
]

export const adminFeature = [
    {
        icon:PiTelegramLogoBold,
        title:'New Messages',
        desc:'Due to its wide spread use as filler text'
    },
    {
        icon:RiMailStarLine,
        title:'Latest Proposals',
        desc:'Due to its wide spread use as filler text'
    },
    {
        icon:RiHourglassLine,
        title:'Package Expiry',
        desc:'Due to its wide spread use as filler text'
    },
    {
        icon:RiHeartLine,
        title:'Saved Items',
        desc:'Due to its wide spread use as filler text'
    }
]

export const appointmentData = [
    {
        id:1,
        clientImage: client1,
        clientName: 'Howard Tanner',
        age:'25',
        gender: 'Male',
        department:'Cardiology',
        date:'13th Sep 2023',
        time:'11:00AM',
        drImage:dr1,
        drName:'Dr. Calvin Carlo',
        fees:'$50/Patient'
    },
    {
        id:2,
        clientImage: client2,
        clientName: 'Wendy Filson',
        age:'28',
        gender: 'Female',
        department:'Gynecology',
        date:'29th Nov 2023',
        time:'11:00AM',
        drImage:dr2,
        drName:'Dr. Cristino Murphy',
        fees:'$50/Patient'
    },
    {
        id:3,
        clientImage: client3,
        clientName: 'Faye Bridger',
        age:'28',
        gender: 'Female',
        department:'Psychotherapy',
        date:'29th Dec 2023',
        time:'02:30PM',
        drImage:dr3,
        drName:'Dr. Alia Reddy',
        fees:'$50/Patient'
    },
    {
        id:4,
        clientImage: client4,
        clientName: 'Ronald Curtis',
        age:'25',
        gender: 'Male',
        department:'Orthopedic',
        date:'13th March 2023',
        time:'01:00PM',
        drImage:dr4,
        drName:'Dr. Toni Kovar',
        fees:'$50/Patient'
    },
    {
        id:5,
        clientImage: client5,
        clientName: 'Melissa Hibner',
        age:'28',
        gender: 'Female',
        department:'Dental',
        date:'5th May 2023',
        time:'10:00AM',
        drImage:dr5,
        drName:'Dr. Jessica McFarlane',
        fees:'$50/Patient'
    },
    {
        id:6,
        clientImage: client6,
        clientName: 'Randall Case',
        age:'25',
        gender: 'Male',
        department:'Orthopedic',
        date:'19th June 2023',
        time:'09:00AM',
        drImage:dr6,
        drName:'Dr. Toni Kovar',
        fees:'$50/Patient'
    },
    {
        id:7,
        clientImage: client7,
        clientName: 'Jerry Morena',
        age:'25',
        gender: 'Male',
        department:'Dentist',
        date:'20th June 2023',
        time:'02:00PM',
        drImage:dr7,
        drName:'Dr. Jessica McFarlane',
        fees:'$50/Patient'
    },
    {
        id:8,
        clientImage: client8,
        clientName: 'Lester McNally',
        age:'25',
        gender: 'Male',
        department:'Gastrology',
        date:'31st Aug 2023',
        time:'01:00PM',
        drImage:dr8,
        drName:'Dr. Elsie Sherman',
        fees:'$50/Patient'
    },
    {
        id:9,
        clientImage: client9,
        clientName: 'Christopher Burrell',
        age:'28',
        gender: 'Female',
        department:'Urology',
        date:'1st Sep 2023',
        time:'01:00PM',
        drImage:dr9,
        drName:'Dr. Bertha Magers',
        fees:'$50/Patient'
    },
    {
        id:10,
        clientImage: client10,
        clientName: 'Mary Skeens',
        age:'28',
        gender: 'Female',
        department:'Neurology',
        date:'13th Sep 2023',
        time:'11:00AM',
        drImage:dr10,
        drName:'Dr. Louis Batey',
        fees:'$50/Patient'
    },
]
export const invoiceData = [
    {
        id:'#d01',
        image:client1,
        name:'Howard Tanner',
        phone:'(+12)85-4521-7568',
        amount:'$253',
        date:'13th Sep 2023',
        status:'Unpaid'
    },
    {
        id:'#d02',
        image:client2,
        name:'Wendy Filson',
        phone:'(+12)85-4521-7568',
        amount:'$482',
        date:'29th Nov 2023',
        status:'Paid'
    },
    {
        id:'#d03',
        image:client3,
        name:'Faye Bridger',
        phone:'(+12)85-4521-7568',
        amount:'$546',
        date:'29th Dec 2023',
        status:'Unpaid'
    },
    {
        id:'#d04',
        image:client4,
        name:'Ronald Curtis',
        phone:'(+12)85-4521-7568',
        amount:'$154',
        date:'13th March 2023',
        status:'Unpaid'
    },
    {
        id:'#d05',
        image:client5,
        name:'Melissa Hibner',
        phone:'(+12)85-4521-7568',
        amount:'$458',
        date:'5th May 2023',
        status:'Paid'
    },
    {
        id:'#d06',
        image:client6,
        name:'Randall Case',
        phone:'(+12)85-4521-7568',
        amount:'$548',
        date:'19th June 2023',
        status:'Paid'
    },
    {
        id:'#d07',
        image:client7,
        name:'Jerry Morena',
        phone:'(+12)85-4521-7568',
        amount:'$658',
        date:'20th June 2023',
        status:'Unpaid'
    },
    {
        id:'#d08',
        image:client8,
        name:'Lester McNally',
        phone:'(+12)85-4521-7568',
        amount:'$457',
        date:'31st Aug 2023',
        status:'Unpaid'
    },
    {
        id:'#d09',
        image:client9,
        name:'Christopher Burrell',
        phone:'(+12)85-4521-7568',
        amount:'$586',
        date:'1th Sep 2023',
        status:'Paid'
    },
    {
        id:'#d10',
        image:client10,
        name:'Mary Skeens',
        phone:'(+12)85-4521-7568',
        amount:'$325',
        date:'13th Sep 2023',
        status:'Unpaid'
    },
]
export const mailData = [
    {
        name:'Amy Lucier',
        desc:'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date:'13th Sep 2023'
    },
    {
        name:'Tameika Whittle',
        desc:'The wise man therefore always holds in these matters to this principle of selection.',
        date:'29th Nov 2023'
    },
    {
        name:'Barbara Bayne',
        desc:'We denounce with righteous',
        date:'29th Dec 2023'
    },
    {
        name:'Nita Griffin',
        desc:'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date:'13th March 2023'
    },
    {
        name:'Marc Flythe',
        desc:'The wise man therefore always holds in these matters to this principle of selection.',
        date:'5th May 2023'
    },
    {
        name:'Elaine Hannah',
        desc:'pain avoided.',
        date:'19th June 2023'
    },
    {
        name:'Abraham Edwards',
        desc:'We denounce with righteous',
        date:'20th June 2023'
    },
    {
        name:'Allie Smith',
        desc:'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date:'31st Aug 2023'
    },
    {
        name:'Eleanor Cisco',
        desc:'The wise man therefore always holds in these matters to this principle of selection.',
        date:'1st Sep 2023'
    },
    {
        name:'Troy Turk',
        desc:'pain avoided.',
        date:'13th Sep 2023'
    },
    {
        name:'Tyron Elliott',
        desc:'We denounce with righteous',
        date:'29th Nov 2023'
    },
    {
        name:'Betty Cook',
        desc:'We denounce with righteous',
        date:'29th Dec 2023'
    },
    {
        name:'Doris Morrison',
        desc:'In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do.',
        date:'13th March 2023'
    },
    {
        name:'Chad Potter',
        desc:'The wise man therefore always holds in these matters to this principle of selection.',
        date:'5th May 2023'
    },
]
export const experienceData = [
    {
        time:'2010 - 2014',
        title:'Master Of Science',
        name:'X.Y.Z Hospital (NZ)'
    },
    {
        time:'2014 - 2016',
        title:'Gynecology Test',
        name:'X.Y.Z Hospital (NZ)'
    },
    {
        time:'2016 - 2019',
        title:'Master Of Medicine',
        name:'X.Y.Z Hospital (NZ)'
    },
    {
        time:'2019 - 2020',
        title:'Orthopedics',
        name:'X.Y.Z Hospital (NZ)'
    },
    {
        time:'2020 to continue..',
        title:'Gynecologist (Final)',
        name:'X.Y.Z Hospital (NZ)'
    },
]
export const patientsData2 = [
    {
        id:'#d0001',
        image:client1,
        name:'Howard Tanner',
        gender:'Male',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0002',
        image:client2,
        name:'Wendy Filson',
        gender:'Female',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0003',
        image:client3,
        name:'Faye Bridger',
        gender:'Female',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0004',
        image:client4,
        name:'Ronald Curtis',
        gender:'Male',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0005',
        image:client5,
        name:'Melissa Hibner',
        gender:'Female',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0006',
        image:client6,
        name:'Randall Case',
        gender:'Male',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0007',
        image:client7,
        name:'Jerry Morena',
        gender:'Male',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0008',
        image:client8,
        name:'Lester McNally',
        gender:'Male',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0009',
        image:client9,
        name:'Christopher Burrell',
        gender:'Female',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
    {
        id:'#d0010',
        image:client10,
        name:'Mary Skeens',
        gender:'Female',
        Age:'25Year',
        country:'USA',
        Diagnosis:'Heart Attack'
    },
]
export const chatData = [
    {
        image:client1,
        name: 'Christopher',
        time: '10 Min',
        message: 'Hello',
        status:'online',
        active: true
    },
    {
        image:dr1,
        name: 'Dr. Cristino',
        time: '20 Min',
        message: 'Hi, How are you?',
        status:'offline',
        badge:'2',
        active: false
    },
    {
        image:client2,
        name: 'Faye',
        time: '30 Min',
        message: 'Heyy',
        status:'offline',
        active: false
    },
    {
        image:client3,
        name: 'Ronald',
        time: '2 Hours',
        message: 'Hey, How are you sir?',
        status:'offline',
        active: false
    },
    {
        image:client4,
        name: 'Melissa',
        time: '3 Hours',
        message: 'Good Afternoon',
        status:'online',
        active: false
    },
    {
        image:dr2,
        name: 'Dr. Elsie',
        time: '10 Hours',
        message: 'Good Morning sir, how can i help you?',
        status:'online',
        active: false
    },
    {
        image:client5,
        name: 'Jerry',
        time: '16 Hours',
        message: 'Please give me appointment',
        status:'online',
        active: false
    },
    {
        image:dr3,
        name: 'Dr. Louis',
        time: '1 Days',
        message: 'Hii',
        status:'offline',
        active: false
    },
    {
        image:client6,
        name: 'Randall',
        time: '2 Days',
        message: 'Hello Sir',
        status:'offline',
        active: false
    },
    {
        image:client7,
        name: 'Mary',
        time: '3 Days',
        message: 'How are you sir?',
        status:'offline',
        active: false
    },
    {
        image:client8,
        name: 'Lester',
        time: '4 Days',
        message: 'Hello',
        status:'Hello please give me answer.',
        active: false
    },
]
export const payment =[
    {
        image:payment1,
        name:'Mastercard',
        id:'•••• 4584',
        expires:'Expires 12/24'
    },
    {
        image:payment2,
        name:'Discover',
        id:'•••• 5796',
        expires:'Expires 12/24'
    },
    {
        image:payment3,
        name:'Rupay',
        id:'•••• 4645',
        expires:'Expires 12/24'
    },
    {
        image:payment4,
        name:'American',
        id:'•••• 4875',
        expires:'Expires 12/24'
    },
]
export const appointment =[
    {
        icon:RiHeartPulseLine,
        title:'Cardiogram',
        name:'Dr. Calvin Carlo',
        date:'13 March',
        class:'h3 fw-normal text-primary mb-0'
    },
    {
        icon:RiStethoscopeLine,
        title:'Checkup',
        name:'Dr. Cristino Murphy',
        date:'5 May',
        class:'h3 fw-normal text-success mb-0'
    },
    {
        icon:RiVirusLine,
        title:'Covid Test',
        name:'Dr. Alia Reddy',
        date:'19 June',
        class:'h3 fw-normal text-warning mb-0'
    },
    {
        icon:RiDossierLine,
        title:'Dentist',
        name:'Dr. Toni Kovar',
        date:'20 June',
        class:'h3 fw-normal text-secondary mb-0'
    },
    {
        icon:RiEye2Line ,
        title:'Eye Test',
        name:'Dr. Jessica McFarlane',
        date:'31 Aug',
        class:'h3 fw-normal text-info mb-0'
    },
    {
        icon:RiMicroscopeLine,
        title:'Orthopedic',
        name:'Dr. Elsie Sherman',
        date:'1 Sep',
        class:'h3 fw-normal text-danger mb-0'
    },
]

export const paymentTwo = [
    {
        name:'Cardiogram',
        title:'Full bill paid',
    },
    {
        name:'Covid Test',
        title:'Full bill paid',
    },
    {
        name:'Checkup',
        title:'Full bill paid',
    },
    {
        name:'Dentist',
        title:'Full bill paid',
    },
    {
        name:'Eye Test',
        title:'Full bill paid',
    },
    {
        name:'Orthopedic',
        title:'Full bill paid',
    },
]

export const MonthlyReport = [
    {
        title:'Treatment report',
        progress:'84%'
    },
    {
        title:'State of being report',
        progress:'79%'
    },
    {
        title:'Health department report',
        progress:'95%'
    },
    {
        title:'Questionnaire',
        progress:'90%'
    },
    {
        title:'Covid-19 report',
        progress:'75%'
    },
    {
        title:'Blood pressure report',
        progress:'97%'
    },
]
export const patientProfile = [
    {
        icon:FiUser,
        title:'Gender',
        name:'Female'
    },
    {
        icon:FiMail,
        title:'Birthday',
        name:'13th Sep 1993'
    },
    {
        icon:VscBook,
        title:'Phone No.',
        name:'+(125) 458-8547'
    },
    {
        icon:MdFormatItalic,
        title:'Address',
        name:'Sydney, Australia'
    },
    {
        icon:GiMedicalDrip,
        title:'Blood Group',
        name:'B +'
    },
]
export const pharmaCategories = [
    {
        image:category1,
        title1:'Pele',
        title2:'Cuidados'
    },
    {
        image:category2,
        title1:'Sexual',
        title2:'Tratamento'
    },
    {
        image:category3,
        title1:'Peso',
        title2:'Gestão'
    },
    {
        image:category4,
        title1:'Dor',
        title2:'Alivio'
    },
    {
        image:category5,
        title1:'Coração',
        title2:'Saúde'
    },
    {
        image:category6,
        title1:'Toce',
        title2:'& Constipação'
    },
    {
        image:category7,
        title1:'Diabetes',
        title2:'Cuidado'
    },
    {
        image:category8,
        title1:'Cancer',
        title2:'Cuidado'
    },
]
export const franchiseProcess = [
    {
        icon:FiAirplay,
        title:'Registration'
    },
    {
        icon:FiMapPin,
        title:'Store Location'
    },
    {
        icon:FiBriefcase,
        title:'Agreement'
    },
    {
        icon:LuFileCheck ,
        title:'Documentation'
    },
    {
        icon:MdOutlineStorefront,
        title:'Store Execution'
    },
    {
        icon:LuAward,
        title:'Inauguration'
    },
]
export const productData = [
    {
        id:1,
        image:product1,
        name:'Thermometer',
        price:'$16.00'
    },
    {
        id:2,
        image:product2,
        name:'Stethoscope',
        price:'$16.00'
    },
    {
        id:3,
        image:product3,
        name:'Pulse oximeter',
        price:'$16.00'
    },
    {
        id:4,
        image:product4,
        name:'Medicine pills',
        price:'$16.00'
    },
    {
        id:5,
        image:product5,
        name:'Smokill smoking habit',
        price:'$16.00'
    },
    {
        id:6,
        image:product6,
        name:'Sanitizer',
        price:'$16.00'
    },
    {
        id:7,
        image:product7,
        name:'Nicotex',
        price:'$16.00'
    },
    {
        id:8,
        image:product8,
        name:'Medigrip',
        price:'$16.00'
    },
    {
        id:9,
        image:product9,
        name:'Face masks',
        price:'$16.00'
    },
    {
        id:10,
        image:product10,
        name:'Dettol handwash',
        price:'$16.00'
    },
    {
        id:11,
        image:product11,
        name:'Herbal care product',
        price:'$16.00'
    },
    {
        id:12,
        image:product12,
        name:'Medical equptment',
        price:'$16.00'
    },
    {
        id:13,
        image:product13,
        name:'Praganews',
        price:'$16.00'
    },
    {
        id:14,
        image:product14,
        name:'Ashwagandha churna',
        price:'$16.00'
    },
    {
        id:15,
        image:product15,
        name:'Beby products',
        price:'$16.00'
    },
    {
        id:16,
        image:product16,
        name:'Kidney tray',
        price:'$16.00'
    },
]

export const unidadeData = [
    {
        id:1,
        image:unidade1,
        name:'Hospital Geral',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
    {
        id:2,
        image:unidade2,
        name:'Hospital Maternidade',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
    {
        id:3,
        image:unidade3,
        name:'Hospital Pediatrico',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
    {
        id:4,
        image:unidade4,
        name:'Hospital Da Centralidade',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
    {
        id:5,
        image:unidade5,
        name:'Centro Médico Gaspar',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
    {
        id:6,
        image:unidade6,
        name:'Consultor Externo',
        price:'Avenida Pedro de Castro Van-Dúnem Loy, Gamek.'
    },
]

export const cartData = [
    {
        image:product14,
        name:'Ashwagandha Churna',
        price:'$ 255.00',
        priceTwo:'$510.00'
    },
    {
        image:product17,
        name:'Diabend',
        price:'$ 520.00',
        priceTwo:'$520.00'
    },
    {
        image:product18,
        name:'Facewash',
        price:'$ 160.00',
        priceTwo:'$160.00'
    },
    {
        image:product10,
        name:'Dettol handwash',
        price:'$ 260.00',
        priceTwo:'$260.00'
    },
]
export const faqAbout = [
    {
        icon:VscBook,
        title:'Get started',
        desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
    {
        icon:LiaFileInvoiceDollarSolid,
        title:'Pricing plan',
        desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
    {
        icon:FiThumbsUp,
        title:'Sales questions',
        desc:'There is now an abundance of readable dummy texts required purely to fill a space.'
    },
]
export const acccordianData = [
    {
        id:1,
        title:'Como posso agendar uma consulta?',
        desc: 'Você pode agendar uma consulta ligando para nossa central de atendimento ou acessando nosso site e preenchendo o formulário de agendamento online.'
    },
    {
        id:2,
        title:'Quais serviços de emergência vocês oferecem?',
        desc: 'Nossa equipe está preparada para lidar com uma ampla gama de emergências médicas, oferecendo atendimento imediato para condições graves e estabilização até que o tratamento adicional possa ser realizado.'
    },
    {
        id:3,
        title:'Vocês aceitam planos de saúde?',
        desc: 'Sim, aceitamos diversos planos de saúde. Entre em contato com nossa equipe de atendimento para verificar se seu plano é aceito e obter informações sobre cobertura.'
    },
    {
        id:4,
        title:'Quais são os horários de funcionamento do hospital?',
        desc: 'O hosipital funciona de segunda a sexta, das 8h às 18h, e aos sábados, das 8h às 12h. Para emergências, estamos disponíveis 24 horas por dia, 7 dias por semana.'
    },
]
export const commentData = [
    {
        image:client1,
        name:'Lorenzo Peterson',
        date:'13th March 2023 at 01:25pm',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client2,
        name:'Tammy Camacho',
        date:'5th May 2023 at 10:00am',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client3,
        name:'Tammy Camacho',
        date:'19th June 2023 at 09:00am',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
    {
        image:client4,
        name:'Lorenzo Peterson',
        date:'20th June 2023 at 02:00pm',
        desc:'" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "'
    },
]

export const recentPost = [
    {
        id:1,
        image: blog1,
        title: 'Novas Tecnologias de Diagnóstico',
        like: '10',
        comment:'00',
        date: '05 Abril de 2024',
        time:'5 min leitura'
    },
    {
        id:2,
        image: blog2,
        title: 'Campanha de Vacinação Infantil',
        like: '33',
        comment:'08',
        date: '12 Abril de 2024',
        time:'5 min de leitura'
    },
    {
        id:3,
        image: blog3,
        title: 'Semana da Saúde da Mulher',
        like: '14',
        comment:'02',
        date: '18 Abril de 2024',
        time:'5 min de leitura'
    },
]
export const contact = [
    {
        icon:FiPhone,
        title:'Telefone',
        desc:'Bons doctores se preciar de assistencia effectiva para sua familia.', 
        link:'tel:+244936551407',
        name:'+152 534-468-854'
    },
    {
        icon:FiMail,
        title:'Email',
        desc:'Envie-nos um e-mail se precisar de assistencia, e para sua familia.' ,
        link:'mailto:contacto@exemplo.com',
        name:'contacto@exemplo.com'
    },
    {
        icon:FiMapPin,
        title:'Local',
        desc:"C/54 Rua Augusto Ngangula, Cidade 558, Luanda, ANGOLA 485",
        link:'#',
        name:'Ver no Google map' 
    },
]