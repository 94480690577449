/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";
import { Link, useParams } from "react-router-dom";

import profileImage from '../../assets/images/doctors/doctor_perfil.png'

import Navbar from "../../components/navbar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";

import { doctorData, experienceData, patientsData, partners } from "../../data/data";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function DoctorProfile() {

    const { id } = useParams();

    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [paragrafos, setParagrafos] = useState([]);
    const [doctor, setDoctor] = useState([]);

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, [id]);


    useEffect(() => {
        setDoctor(info[0]?.doctor || {});
        setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
      }, [info]);
    

    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina-single/3/' + id);

            console.log("info :");
            console.log(res.data);


            setInfo(res.data);

            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);
            setDoctor(info[0]?.doctor || {});
            setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    let [activeIndex, setActiveIndex] = useState(1)

    let settings = {
        container: '.slider-range-four',
        items: 4,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 4
            },

            767: {
                items: 2
            },


            320: {
                items: 1
            },
        },
    };

    let settings2 = {
        container: '.client-review-slider',
        items: 1,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 16,
    }
    return (
        <>
            <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />


            {info != null ? <>
                <section className="bg-dashboard my-lg-5">
                <div className="container mt-xl-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 rounded shadow">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-5 position-relative">
                                        <img src={url_root+"/storage/"+doctor?.fotografia} className="img-fluid dr-profile-img" alt="" />
                                    </div>

                                    <div className="col-xl-8 col-lg-8 col-md-7">
                                        <div className="p-lg-5 p-4">
                                            <small className="text-muted">{doctor?.nivel_academico}</small>

                                            <h4 className="my-3">Saudações ! <br /> <span className="text-primary h2">{doctor?.name}</span></h4>
                                            <p className="para-desc text-muted">{paragrafos[0]?.descricao}</p>
                                            <p className="para-desc text-muted">{paragrafos[1]?.descricao}</p>
                                            <p className="para-desc text-muted">{paragrafos[2]?.descricao}</p>

                                            {/* <h6 className="mb-0">You have <span className="text-primary">18 patients</span> remaining today!</h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Outros dados do doctor */}

                    
                </div>
            </section>
	</> : <></>}
            

            <AdminFooter />

            <ScrollTop />
        </>
    )
}