import React from "react";

import bg1 from '../../assets/images/cta.jpeg'

import { ctaData } from "../../data/data";

import AuthUser from "../AuthUser";



import {
    RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
    RiMapPin2Line,
    FiFacebook,
    FiLinkedin,
    FiGithub,
    MdOutlineEventNote,
    FiCalendar,
    FiClock,
    FiHeart, FiEye, RiUser2Line, FiTwitter, FiArrowRight
} from "./../../assets/icons/vander";


const icons = [
    { nome: 'olhos', icon: RiEyeFill },
    { nome: 'cabeça', icon: RiPsychotherapyFill },
    { nome: 'ostetoscopio', icon: RiStethoscopeFill },
    { nome: 'capsula', icon: RiCapsuleFill },
    { nome: 'microscopio', icon: RiMicroscopeFill },
    { nome: 'cardiologia', icon: RiPulseFill },
    { nome: 'coracao', icon: RiEmpathizeFill },
    { nome: 'celulas', icon: RiMindMap },
    { nome: 'cerebro', icon: RiMentalHealthLine },
    { nome: 'apple', icon: RiAppleLine },
    { nome: 'fratura', icon: RiInfraredThermometerLine },
    { nome: 'calendar', icon: FiCalendar },
    { nome: 'doctor', icon: GrUserPolice },
    { nome: 'pasta', icon: RiBriefcase4Line },
    { nome: 'projetor', icon: FiAirplay },
    { nome: 'janela', icon: MdOutlineFlip },
];

export default function CtaTwo({ paragrafos }) {

    const { http, url_root } = AuthUser();

        const getIconByName = (name) => {
            const iconObject = icons.find(icon => icon.nome === name);
            console.log(`Finding icon for ${name}:`, iconObject);
            return iconObject ? iconObject.icon : null;
          };


    return (

            <section className="section mt-60" style={{ backgroundImage: `url(${url_root + "/storage/" + paragrafos[3]?.imagem})` }}>
                <div className="bg-overlay bg-overlay-dark" style={{ opacity: '0.3' }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-7 col-md-7 offset-md-5">
                            {paragrafos?.slice(3, 6).map((item, index) => {
                                const Icon = getIconByName(item?.icone);
                                if (!Icon) return null; // Ensure Icon is not null
                                return (
                                    <div className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4" key={index}>
                                        <Icon className="icons mb-0 text-primary display-2" />
                                        <div className="ms-3">
                                            <h5 className="titles">{item.titulo}</h5>
                                            <p className="text-muted para mb-0">{item.descricao}</p>
                                        </div>
                                        <div className="big-icon">
                                            <Icon />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

    )
}