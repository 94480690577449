import React, { useState, useEffect } from "react";
import { patientsData } from "../../data/data";

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import AuthUser from "../AuthUser";

const settings = {
    container: '.client-review-slider',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
};

export default function CtaThree({doctores}) {

    const { url_root } = AuthUser();

    useEffect(() => {
           
      }, [doctores]);

    return (<div className="container">
        <div className="row" >
            <div className="offset-lg-8 offset-md-6 col-lg-4 col-md-6" >
                <div className="cta-review bg-primary shadow-md mb-lg-4 rounded p-4 py-5" >
                    <div className="client-review-slider" >
                        <TinySlider settings={settings} > {
                            doctores?.map((item, index) => {
                                return (<div className="tiny-slide"
                                    key={index} >
                                    <p className="text-white-50 fst-italic" > {item?.habilidades} </p>

                                    <div className="d-inline-flex align-items-center" >
                                        <img src={url_root + "/storage/"+item?.fotografia}
                                            className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                                            alt="" />
                                        <h6 className="text-light title-dark fw-normal ms-3" > {item?.name} <small className="text-white-50 d-block" > {item?.nivel_academico} </small></h6>
                                    </div>
                                    </div>
                                )
                            })
                        } </TinySlider> </div > </div> </div > </div> </div >
    )
}