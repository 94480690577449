/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import { Link } from "react-router-dom";

import bg1 from '../assets/images/bg/faq.jpeg'

import Navbar from "../components/navbar";

import { RiSearchLine, FiArrowRight, FiPhone, FiMail } from '../assets/icons/vander'
import { faqAbout } from "../data/data";
import Accordion from "../components/accordion";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

export default function Faqs() {

    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);

    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, []);


    useEffect(() => {
        console.log('Unidades:', unidades);
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina/4');

            console.log("info :");
            console.log(res.data);

            setInfo(res.data);

            console.log('Doctores');
            console.log(info[0]?.doctores);
            console.log('Consultas');
            console.log(info[0]?.consultas);

            setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };


    return (
        <>
            <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container" />

            {info[0]?.banner != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h2 className="sub-title text-white title-dark"> {info[0]?.banner?.designacao}{" "}</h2>
                                </div>
                                <div className="subcribe-form text-center mt-4">
                                    <form>
                                        <input type="text" id="name" name="name" className="border bg-white-50 rounded-pill" required="" placeholder="Faça uma pergunta..." />
                                        <button className="btn btn-pills btn-primary"><RiSearchLine className="mb-0 align-middle me-1" /> Procure</button>
                                    </form>
                                    <p className="text-white-50 mb-0 para-desc mx-auto mt-3">{info[0]?.banner?.descricao}{" "}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}


            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">

                {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-4">Choose a category to quickly find</h4>
                            <p className="text-muted para-desc mx-auto mb-0">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {faqAbout.map((item,index)=>{
                        let Icon = item.icon
                        return(
                            <div className="col-lg-4 col-md-6 mt-4 pt-2" key={index}>
                                <div className="card features feature-primary text-center border-0">
                                    <div className="icon text-center rounded-md mx-auto">
                                        <Icon className="h3 mb-0"/>
                                    </div>
                                    <div className="card-body p-0 mt-3">
                                        <Link to="#" className="title text-dark h5">{item.title}</Link>
                                        <p className="text-muted mt-3">{item.desc}</p>
                                        <Link to="#" className="link">Read More <FiArrowRight className="mb-0 align-middle"/></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}

                <div className="container mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="mb-4">{info[0]?.paragrafos[0]?.titulo}</h4>
                                <p className="para-desc mx-auto text-muted mb-0">{info[0]?.paragrafos[0]?.descricao}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-9 mt-4 pt-2">
                            <Accordion perguntas={info[0]?.paragrafos} />
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="mb-4">{info[0]?.paragrafos[1]?.titulo}</h4>
                                <p className="para-desc mx-auto text-muted mb-0">{info[0]?.paragrafos[1]?.descricao}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-6 mt-4 pt-2">
                                    <div className="card border-0 text-center features feature-primary rounded">
                                        <div className="icon text-center mx-auto rounded-md">
                                            <FiPhone className="h3 mb-0" />
                                        </div>

                                        <div className="card-body p-0 mt-4">
                                            <h5>Telefone</h5>
                                            {/* <p className="text-muted">Bons doctores se preciar de assistencia effectiva para sua familia.</p> */}
                                            <Link to="tel:+152534-468-854" className="link">{info[0]?.footer[1].titulo}</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4 pt-2">
                                    <div className="card border-0 text-center features feature-primary rounded">
                                        <div className="icon text-center mx-auto rounded-md">
                                            <FiMail className="h3 mb-0" />
                                        </div>

                                        <div className="card-body p-0 mt-4">
                                            <h5>Email</h5>
                                            {/* <p className="text-muted">Envie-nos um e-mail se precisar de assistencia, para sí e para sua familia.</p> */}
                                            <Link to="mailto:contact@example.com" className="link">{info[0]?.footer[0].titulo}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />


            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}